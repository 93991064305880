import * as actions from 'services/actions/tagActions'
import randomColor from 'randomcolor'
import React, { useEffect } from 'react'
import { Button } from '@sc/components/Button'
import { categoriesSelectors } from 'store/categories'
import { ColorPicker } from '@sc/components/ColorPicker'
import { Div } from '@sc/components/Div'
import { Form } from '@sc/components/form/Form'
import { FormGroup } from '@sc/components/form/FormGroup'
import { IconBox } from '@sc/components/box/IconBox'
import { Input } from '@sc/components/Input'
import { isValidHexColor, normalizeName, slugify } from 'utils'
import { Label } from '@sc/components/Label'
import { Modal } from '@sc/patterns/Modal'
import { Select } from '@sc/components/Select'
import { Tag as OdoTag } from 'types/tag'
import { tagsSelectors } from 'store/tags'
import { TextArea } from '@sc/components/TextArea'
import { useForm } from 'react-hook-form'
import { useOdoSelector } from 'store/store'
import { useTranslation } from 'react-i18next'

type Form = {
  name: string
  color: string
  description?: string
  categoryId: string
}

const nameValidator = (
  name: string,
  tag: OdoTag | undefined,
  tags: Array<OdoTag>
) => {
  const slug = slugify(name)

  const duplicated =
    tags.findIndex(t => t.name === slug && t.id !== tag?.id) !== -1

  return duplicated ? `There is already a tag named "${name}"` : undefined
}

const colorValidator = (color: string) => {
  return isValidHexColor(color) ? undefined : 'Invalid color code'
}

type TagModalProps = {
  tagId?: string
  defaultValues?: Partial<Omit<OdoTag, 'id'>>
  onClose: (tag?: OdoTag) => void
}

export const TagModal = ({
  tagId,
  defaultValues = {},
  onClose
}: TagModalProps) => {
  const { t } = useTranslation()

  const tags = useOdoSelector(tagsSelectors.selectAll)
  const categories = useOdoSelector(categoriesSelectors.selectAll)

  const tag = useOdoSelector(state =>
    tagId ? tagsSelectors.selectByIdColorCorrected(state, tagId) : undefined
  )

  const category = useOdoSelector(state =>
    tag && tag.categoryId
      ? categoriesSelectors.selectById(state, tag.categoryId)
      : undefined
  )

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<Form>({
    defaultValues: {
      categoryId: 'none',
      color: randomColor(),
      ...defaultValues
    }
  })

  // If we're editing tag, fill the fields with the current value
  useEffect(() => {
    if (tag) {
      setValue('name', tag.name)
      setValue('color', tag.color)
      setValue('description', tag.description)
      setValue('categoryId', tag.categoryId ? tag.categoryId : 'none')
    }
  }, [tag, setValue])

  const toggleTagArchive = () => {
    if (tag) actions.updateArchive(tag.id, !tag.archived)
    onClose()
  }

  const onSubmit = async (values: Form) => {
    if (tag && tag.archived) return onClose()

    if (tag) {
      actions.update(tag.id, values)
      onClose()
    } else {
      const newTag = actions.create(values)
      onClose(newTag)
    }
  }

  const currentColor = watch('color')
  const currentCategoryId = watch('categoryId')

  return (
    <Modal.Root
      open
      onOpenChange={value => {
        if (!value) onClose()
      }}
    >
      <Modal.Content>
        <Modal.Title>{tag ? 'Edit tag' : 'Create tag'}</Modal.Title>

        <Modal.Section>
          <Form id="tag-form" onSubmit={handleSubmit(onSubmit)}>
            {tag && tag.archived && (
              <TextArea centered>{t('tagModal.archiveNotice')}</TextArea>
            )}

            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="name">
                  {t('tagModal.name.label')}
                </Label.Primary>
              </Label.Root>

              <FormGroup orientation="horizontal">
                <input
                  type="hidden"
                  {...register('color', {
                    required: {
                      value: true,
                      message: 'The field "color" is required'
                    },
                    validate: color => colorValidator(color)
                  })}
                />

                <ColorPicker
                  value={currentColor}
                  onValueChange={value => setValue('color', value)}
                  disabled={Boolean(tag?.archived || category?.color)}
                />

                <Input
                  error={errors.name}
                  type="text"
                  placeholder={t('tagModal.name.placeholder')}
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'The field "name" is required'
                    },
                    validate: name => nameValidator(name, tag, tags)
                  })}
                  onChange={e =>
                    setValue('name', normalizeName(e.target.value))
                  }
                  disabled={tag && tag.archived}
                />
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="description">
                  {t('tagModal.description.label')}
                </Label.Primary>
              </Label.Root>

              <Input
                error={errors.description}
                type="text"
                placeholder={t('tagModal.description.placeholder')}
                {...register('description')}
                disabled={tag && tag.archived}
              />
            </FormGroup>

            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="category">
                  {t('category')}
                </Label.Primary>
              </Label.Root>

              <Select.Root
                value={currentCategoryId}
                onValueChange={value => setValue('categoryId', value)}
                defaultValue="none"
                disabled={tag && tag.archived}
              >
                <Select.Item value="none">
                  <Div css={{ display: 'flex', alignItems: 'center' }}>
                    <IconBox
                      variant="small"
                      name="circle"
                      iconCss={{
                        color: '$surfaceBg',
                        marginRight: '0.2rem'
                      }}
                    />
                    No category
                  </Div>
                </Select.Item>

                {categories.map(category => (
                  <Select.Item key={category.id} value={category.id}>
                    <Div css={{ display: 'flex', alignItems: 'center' }}>
                      <IconBox
                        variant="small"
                        name="circle"
                        iconCss={{
                          color: category.color || '$surfaceBg',
                          marginRight: '0.2rem'
                        }}
                      />
                      {category.name}
                    </Div>
                  </Select.Item>
                ))}
              </Select.Root>
            </FormGroup>
          </Form>
        </Modal.Section>

        <Modal.Actions>
          <Modal.ActionsLeft>
            {tag ? (
              <Button
                type="button"
                variant={tag.archived ? 'secondary' : 'danger'}
                onClick={event => {
                  event.preventDefault()
                  toggleTagArchive()
                }}
              >
                {tag.archived ? t('unarchive') : t('archive')}
              </Button>
            ) : (
              <Modal.Close variant="secondary">{t('cancel')}</Modal.Close>
            )}
          </Modal.ActionsLeft>

          <Modal.ActionsRight>
            {tag && (
              <Modal.Close variant="secondary">{t('cancel')}</Modal.Close>
            )}

            <Button disabled={isSubmitting} type="submit" form="tag-form">
              {isSubmitting
                ? t('wait')
                : tag
                ? tag.archived
                  ? t('done')
                  : t('save')
                : t('create')}
            </Button>
          </Modal.ActionsRight>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Root>
  )
}
