import { ComponentProps } from 'react'
import { styled } from '@sc/theme/stitches.config'
import {
  disabledProps,
  disabledStyles,
  shapeStyles,
  variantStyles
} from './boxStyles'

const Box = styled('div', {
  display: 'block',
  ...disabledStyles,
  variants: {
    ...variantStyles,
    ...shapeStyles
  },
  defaultVariants: {
    variant: 'large',
    shape: 'square'
  }
})

export type ColorBoxProps = Omit<ComponentProps<typeof Box>, 'children'> & {
  background: string
  disabled?: boolean
}

export const ColorBox = ({
  background,
  disabled,
  css = {},
  ...props
}: ColorBoxProps) => {
  return (
    <Box css={{ background, ...css }} {...disabledProps(disabled)} {...props} />
  )
}
