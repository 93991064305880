import { useTheme } from 'next-themes'
import { theme, darkTheme } from 'theme/stitches.config'

export const useTransformColorTokenToValue = () => {
  const { theme: currentTheme } = useTheme()

  const transformTokenToValue = (themeToken: keyof typeof theme.colors) => {
    const tokenValue =
      currentTheme === 'dark'
        ? darkTheme.colors[themeToken]
        : theme.colors[themeToken]
    if (!tokenValue) {
      console.error(`token ${themeToken} not found in theme`)
      return
    }

    return tokenValue.value
  }

  return transformTokenToValue
}
