import { supabase } from './supabase'
import { toOdoTutorial } from './mappers'
import { Tutorial } from 'types/tutorial'

export const fetcher = async (
  uid: string,
  spaceId: string
): Promise<Tutorial | null> => {
  const { data } = await supabase
    .from('tutorials')
    .select('*')
    .match({ user_id: uid, space_id: spaceId })
    .throwOnError()

  return data && data[0] ? toOdoTutorial(data[0]) : null
}

export const updater = async (
  id: string,
  changes: Pick<Tutorial, 'completed'>
) => {
  const { data } = await supabase
    .from('tutorials')
    .update(changes)
    .eq('id', id)
    .select()

  if (!data) {
    throw new Error('Failed to update tutorial')
  }

  return toOdoTutorial(data[0])
}
