import { css, keyframes } from 'theme/stitches.config'

const blinker = keyframes({
  '0%': { opacity: 0.1 },
  '100%': { opacity: 1 }
})

export const dot = css({
  size: '1rem',
  borderRadius: '$full',
  animation: `${blinker} 1s linear infinite`,
  backgroundColor: '$fgMed'
})
