import * as Separator from '@radix-ui/react-separator'
import { styled } from '../theme/stitches.config'

export const Divider = styled(Separator.Root, {
  '&:not([data-orientation=vertical])': { height: '$borderWidths$default' },
  '&[data-orientation=vertical]': { width: '$borderWidths$default' },

  variants: {
    type: {
      primary: {
        backgroundColor: '$strokeSecondary'
      },
      secondary: {
        backgroundColor: '$strokeTertiary'
      }
    }
  },
  defaultVariants: {
    type: 'primary'
  }
})
