import React, { ComponentProps, ElementRef } from 'react'
import { Body } from '@sc/components/Typography'
import { styled } from '@sc/theme/stitches.config'

const TextAreaContainer = styled('div', {
  minHeight: '6rem',
  padding: '$paddingContainerLevelOne',
  borderRadius: '$S',
  display: 'flex',
  alignItems: 'center',
  '& > p': { flex: 1 },
  variants: {
    variant: {
      theme: {
        backgroundColor: '$surfaceFgMed'
      },
      danger: {
        backgroundColor: '$surfaceDangerHigh',
        '& > p': {
          color: '$fgHighDanger'
        }
      }
    },
    centered: {
      true: {
        textAlign: 'center'
      }
    }
  },
  defaultVariants: {
    variant: 'theme'
  }
})

type TextAreaRef = ElementRef<typeof TextAreaContainer>
export type TextAreaProps = ComponentProps<typeof TextAreaContainer>

export const TextArea = React.forwardRef<TextAreaRef, TextAreaProps>(
  ({ children, ...props }, ref) => {
    return (
      <TextAreaContainer ref={ref} {...props}>
        <Body variant="small">{children}</Body>
      </TextAreaContainer>
    )
  }
)
