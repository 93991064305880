import useSWR from 'swr'
import { fetcher } from 'services/memberships'
import { useSelector } from 'react-redux'
import { spacesSelectors } from 'store/spaces'

export const useMemberships = () => {
  const spaceId = useSelector(spacesSelectors.currentSpaceId)

  const key = spaceId ? [spaceId, 'memberships'] : null

  const { data, isLoading, mutate } = useSWR(key, ([spaceId]) =>
    fetcher(spaceId)
  )

  const users = data ?? []

  const members = users.filter(user => !user.accessLevel.includes('admin'))

  const admins = users.filter(user => user.accessLevel.includes('admin'))

  return {
    users,
    members,
    admins,
    isLoading,
    mutate
  }
}
