import React, { ComponentProps, ElementRef } from 'react'
import { styled } from '@sc/theme/stitches.config'
import {
  disabledProps,
  disabledStyles,
  shapeStyles,
  variantStyles
} from './boxStyles'

const ImageContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    ...variantStyles
  }
})

const Image = styled('img', {
  display: 'block',
  // Using boxShadow instead of border because of weird artifacts in Chrome.
  boxShadow: '0px 0px 0px $borderWidths$control $colors$strokeSolid',
  objectFit: 'cover',

  ...disabledStyles,

  variants: {
    variant: {
      small: {
        size: 'calc($iconBoxSmall - 2 * $borderWidths$control)'
      },
      large: {
        size: 'calc($controlHeight - 2 * $borderWidths$control)'
      }
    },
    ...shapeStyles
  },

  defaultVariants: {
    variant: 'large',
    shape: 'circle'
  }
})

type PictureBoxRef = ElementRef<typeof ImageContainer>
export type PictureBoxProps = ComponentProps<typeof Image> & {
  disabled?: boolean
}

export const PictureBox = React.forwardRef<PictureBoxRef, PictureBoxProps>(
  ({ disabled, variant = 'large', ...props }, ref) => {
    return (
      <ImageContainer ref={ref} variant={variant}>
        <Image {...disabledProps(disabled)} variant={variant} {...props} />
      </ImageContainer>
    )
  }
)
