export const disabledProps = (disabled?: boolean) => {
  return disabled ? { 'aria-disabled': true } : {}
}

export const disabledStyles = {
  '&[aria-disabled="true"]': {
    opacity: 0.35,
    pointerEvents: 'none'
  }
}

export const variantStyles = {
  variant: {
    small: {
      size: '$iconBoxSmall'
    },
    large: {
      size: '$controlHeight'
    }
  }
}

export const shapeStyles = {
  shape: {
    circle: {
      borderRadius: '$L'
    },
    square: {
      borderRadius: '$M'
    }
  }
}
