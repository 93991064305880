import React, { ComponentProps, ElementRef } from 'react'
import { Icon, IconProps } from '@sc/components/Icon'
import { CSS, styled } from '@sc/theme/stitches.config'
import { disabledProps, disabledStyles, shapeStyles } from './boxStyles'

const StyledBox = styled('div', {
  ...disabledStyles,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&[role="button"]': {
    cursor: 'pointer'
  },

  variants: {
    ...shapeStyles,

    variant: {
      xsmall: {
        size: '$iconBoxSmall'
      },
      small: {
        size: '$iconBoxSmall'
      },
      large: {
        size: '$controlHeight'
      }
    },

    border: {
      true: {
        border: '$borderWidths$control solid $strokeSolid'
      }
    }
  },

  defaultVariants: {
    shape: 'circle'
  }
})

type IconBoxRef = ElementRef<typeof Icon>
type BaseIconBoxProps = ComponentProps<typeof StyledBox>
export type IconBoxProps = IconProps & {
  border?: boolean
  disabled?: boolean
  shape?: 'circle' | 'square'
  variant?: BaseIconBoxProps['variant']
  iconCss?: CSS
}

type Variants = 'xsmall' | 'small' | 'large'

const iconVariant: Record<Variants, IconProps['variant']> = {
  xsmall: 'xsmall',
  small: 'large',
  large: 'large'
}

export const IconBox = React.forwardRef<IconBoxRef, IconBoxProps>(
  ({ variant, disabled, name, iconCss, ...props }, ref) => {
    // differs from Box
    const derivedIcon =
      typeof variant !== 'string' ? variant : iconVariant[variant as Variants]

    return (
      <StyledBox variant={variant} {...disabledProps(disabled)} {...props}>
        <Icon ref={ref} name={name} variant={derivedIcon} css={iconCss} />
      </StyledBox>
    )
  }
)
