import React, { ComponentProps, ElementRef } from 'react'
import tinycolor from 'tinycolor2'
import { Body } from '@sc/components/Typography'
import { styled } from '@sc/theme/stitches.config'
import {
  disabledProps,
  disabledStyles,
  shapeStyles,
  variantStyles
} from './boxStyles'

const Box = styled('div', {
  display: 'grid',
  placeItems: 'center',
  ...disabledStyles,
  variants: {
    ...variantStyles,
    ...shapeStyles
  },
  defaultVariants: {
    variant: 'large',
    shape: 'square'
  }
})

type TextBoxRef = ElementRef<typeof Box>
export type TextBoxProps = ComponentProps<typeof Box> & {
  background: string
  children?: string
  disabled?: boolean
}

export const TextBox = React.forwardRef<TextBoxRef, TextBoxProps>(
  ({ background, disabled, children, css = {}, ...props }, ref) => {
    const bg = tinycolor(background)

    if (!bg.isValid()) console.error('Invalid background color token')

    const color = bg.isLight() ? '$blackSolid' : '$whiteSolid'

    return (
      <Box
        ref={ref}
        css={{ background, ...css }}
        {...disabledProps(disabled)}
        {...props}
      >
        <Body
          as="span"
          css={{ color, userSelect: 'none', textTransform: 'uppercase' }}
          variant={
            typeof props.variant === 'undefined' || props.variant === 'large'
              ? 'small'
              : 'xsmall'
          }
        >
          {children}
        </Body>
      </Box>
    )
  }
)
