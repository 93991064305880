import { apiCall } from 'services/apiCall'
import { captureException } from 'sentry'
import { spacesSelectors } from 'store/spaces'
import { StripeCheckout } from 'types/api'
import { useAlert } from 'joist/modules/alerts'
import { useOdoSelector } from 'store'
import { useState } from 'react'

export type SubscriptionActions = ReturnType<typeof useSubscriptionActions>

export const useSubscriptionActions = (spaceId?: string) => {
  const [redirecting, setRedirecting] = useState(false)

  const alert = useAlert()

  const currentSpaceId = useOdoSelector(state =>
    spaceId ? spaceId : spacesSelectors.currentSpaceId(state)
  )

  const subscribe = async () => {
    try {
      setRedirecting(true)

      const [successUrl, cancelUrl] = [
        '/billing/checkout?session_id={CHECKOUT_SESSION_ID}',
        `/${currentSpaceId}/manage#settings`
      ].map(path => window.location.origin.concat(path))

      const {
        data: { url }
      } = await apiCall<StripeCheckout>('stripe/create-checkout-session', {
        successUrl,
        cancelUrl,
        meta: {
          spaceId: currentSpaceId
        }
      })

      window.open(url, '_self')
    } catch (error) {
      captureException(error)
      setRedirecting(false)
    }
  }

  const manageSubscription = async () => {
    try {
      setRedirecting(true)

      const {
        data: { url }
      } = await apiCall<
        { url: string },
        { returnUrl: string; spaceId: string }
      >('stripe/customer-portal', {
        returnUrl: window.location.href,
        spaceId: currentSpaceId
      })

      window.open(url, '_self')
    } catch (error) {
      captureException(error)
      setRedirecting(false)
      alert({
        message: 'Error redirecting to stripe',
        type: 'error',
        timeout: 2000
      })
    }
  }

  return {
    redirecting,
    actions: {
      subscribe,
      manageSubscription
    }
  }
}
