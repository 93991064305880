import * as service from 'services/tutorials'
import { mutate, unstable_serialize } from 'swr'
import { Tutorial, TutorialItem } from 'types/tutorial'
import { getSpaceId, getUid } from './shared'
import { getKey } from 'hooks/useTutorial'

export const complete = async (tutorial: Tutorial, item: TutorialItem) => {
  const key = unstable_serialize(getKey(getUid(), getSpaceId()))

  const completed = { ...tutorial.completed, [item]: true }

  mutate(key, service.updater(tutorial.id, { completed }), {
    optimisticData: curr => ({ ...curr, completed }),
    rollbackOnError: true
  })
}
