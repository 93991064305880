import { styled, StyledProps } from '@sc/theme/stitches.config'

export const TabRow = styled('div', {
  all: 'unset',
  listStyle: 'none',
  display: 'flex',
  padding: '$paddingUITabbed',
  borderWidth: '$control',
  borderStyle: 'solid',
  alignItems: 'center',
  background: 'transparent',
  borderRadius: '$L',
  variants: {
    variant: {
      fullWidth: {
        width: '100%'
      },
      minWidth: {
        width: 'fit-content'
      }
    },
    fill: {
      true: {
        background: '$surfaceFg'
      }
    },
    stroke: {
      true: {
        borderColor: '$strokeSecondary'
      },
      false: {
        borderColor: 'transparent'
      }
    }
  },
  defaultVariants: {
    stroke: true
  }
})

export type TabRowProps = StyledProps<typeof TabRow>
