import * as PopoverPrimitive from '@radix-ui/react-popover'
import { keyframes, styled } from '../theme/stitches.config'

// @todo DRY from dropdown
const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' }
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' }
})

const StyledContent = styled(PopoverPrimitive.Content, {
  backgroundColor: '$surfaceFg',
  border: 'none',
  borderRadius: '$M',
  boxShadow: '$levelOne',
  pointerEvents: 'auto',
  overflow: 'hidden',
  outline: 'none',
  zIndex: '$popover',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade }
    }
  }
})

export const Popover = PopoverPrimitive.Root
export const PopoverTrigger = PopoverPrimitive.Trigger
export const PopoverContent = StyledContent
export const PopoverAnchor = PopoverPrimitive.Anchor
export const PopoverArrow = PopoverPrimitive.Arrow
export const PopoverClose = PopoverPrimitive.Close
export const PopoverPortal = PopoverPrimitive.Portal

export type PopoverProps = PopoverPrimitive.PopoverProps
export type PopoverContentProps = PopoverPrimitive.PopoverContentProps
