// https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
export function stringToColour(str: string) {
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }

  let colour = '#' // 30% alpha transparency

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }

  return colour
}
