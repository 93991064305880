import { safeStaleCategoriesTagsRecieved } from 'store/thunkActions'
import { store } from 'store'
import { supabase } from './supabase'
import { Tag } from 'types/tag'
import { tagsActions } from 'store/tags'
import { toOdoCategory, toOdoTag, toSupabaseTag } from './mappers'

export const fetcher = async (spaceId: string) => {
  try {
    const categoriesPromise = supabase
      .from('categories')
      .select()
      .eq('space_id', spaceId)
      .order('rank', { ascending: true })
      .throwOnError()
      .then(({ data }) => (data ? data.map(toOdoCategory) : []))

    const tagsPromise = supabase
      .from('tags')
      .select()
      .eq('space_id', spaceId)
      .order('name', { ascending: false })
      .throwOnError()
      .then(({ data }) => (data ? data.map(toOdoTag) : []))

    const categories = await categoriesPromise
    const tags = await tagsPromise

    // SWR triggers data fetch frequently. Sometimes, data will be fetched right before the user switches space.
    // Without this, we would get and push stale data for the old space into redux. We don't want that.
    // A thunk action that checks the space data matches the current selected space.
    const res = store.dispatch(
      safeStaleCategoriesTagsRecieved(categories, tags, spaceId)
    )

    return res
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const creator = async (values: Tag) => {
  const { data } = await supabase
    .from('tags')
    .insert({
      id: values.id,
      name: values.name,
      description: values.description ? values.description : null,
      color: values.color,
      space_id: values.spaceId,
      category_id: values.categoryId ? values.categoryId : null
    })
    .throwOnError()
    .select()

  const tag = toOdoTag(data[0])

  store.dispatch(tagsActions.createTagSucceeded(tag))

  return tag
}

export const updater = async (id: string, update: Partial<Tag>) => {
  const { data } = await supabase
    .from('tags')
    .update(toSupabaseTag(update))
    .eq('id', id)
    .throwOnError()
    .select()

  const tag = toOdoTag(data[0])

  store.dispatch(tagsActions.updateTagSucceeded(tag))

  return tag
}
