import { styled } from '@sc/theme/stitches.config'

const Item = styled('li', {
  all: 'unset',
  boxSizing: 'border-box',
  userSelect: 'none',
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  height: '$controlHeight',
  paddingX: '$paddingUIM',

  fontFamily: '$octave',
  fontSize: '$base',
  lineHeight: '$3',
  fontWeight: '$normal',

  '&:hover': {
    backgroundColor: '$actionTertiary'
  }
})

const Root = styled('ul', {
  all: 'unset',
  display: 'block',
  borderRadius: '$S',
  backgroundColor: '$surfaceFg',
  boxShadow: '$levelOne',
  zIndex: '$popover',
  overflow: 'hidden',
  [`& ${Item}:not(:last-child)`]: {
    borderBottom: '$borderWidths$control solid $strokeTertiary'
  }
})

export const ActionList = {
  Root,
  Item
}
