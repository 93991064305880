import useSWR from 'swr'
import { fetcher } from 'services/tags'
import { useOdoSelector } from 'store/store'
import { spacesSelectors } from 'store/spaces'

export const getKey = (spaceId: string) => (spaceId ? [spaceId, 'tags'] : null)

export const useTags = () => {
  const spaceId = useOdoSelector(spacesSelectors.currentSpaceId)

  const { isLoading } = useSWR(getKey(spaceId), ([spaceId]) => fetcher(spaceId))

  return {
    isLoading
  }
}
