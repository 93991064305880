import { ComponentProps } from 'react'
import { styled } from 'theme/stitches.config'
import { useTranslation } from 'react-i18next'
import * as styles from './loadingStyles'
import { Body } from '@sc/components/Typography'

const Panel = styled('div', {
  stackH: '$2',
  alignItems: 'center'
})

type LoadingProps = {
  text?: string
} & ComponentProps<typeof Panel>

export const Loading = ({ text, ...props }: LoadingProps) => {
  const { t } = useTranslation()

  return (
    <Panel {...props}>
      <div className={styles.dot()} />
      <Body opacity="med" variant="base">
        {text ?? t('loading')}
      </Body>
    </Panel>
  )
}

export const PageLoader = () => (
  <Loading
    css={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }}
  />
)
