import { useCallback, MouseEvent, useRef, useEffect } from 'react'
import { CSS } from '@stitches/react'
import { EnclosedEntity } from '@sc/components/EnclosedEntity'
import { Row, RowLeft, RowRight } from '@sc/components/Row'
import { TextGroup } from '@sc/components/TextGroup'
import { ColorBox } from '@sc/components/box/ColorBox'

type BaseTag = {
  name: string
  color: string
  disabled?: boolean
}

type ExtendedBaseTag = BaseTag & {
  description?: string
  categoryName?: string
}

export interface TagProps<T extends BaseTag> {
  tag: T
  onClick?: (tag: T, event?: MouseEvent<HTMLDivElement>) => void
  onDelete?: (tag: T, event?: MouseEvent<HTMLButtonElement>) => void
  css?: CSS
  disabled?: boolean
  highlighted?: boolean
  hideCategoryName?: boolean
}

export const ExtendedTag = <T extends ExtendedBaseTag>({
  tag,
  disabled,
  onClick,
  highlighted,
  hideCategoryName
}: TagProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      onClick(tag, event)
    },
    [onClick, tag]
  )

  useEffect(() => {
    if (highlighted) {
      // Used to scroll the container when the user is
      // using the keyboard to search for the tag.
      // Wrapped in a setTimeout because just after the popup is being opened
      // is not yet on the correct position and scrolls to top of the page
      setTimeout(() =>
        ref.current?.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        })
      )
    }
  }, [highlighted])

  return (
    <Row
      ref={ref}
      css={{ paddingX: '$paddingUIXL' }}
      hoverable
      onMouseDown={handleClick}
      active={highlighted}
    >
      <RowLeft>
        <TextGroup.Root orientation="vertical" variant="base">
          <TextGroup.Primary>
            {!hideCategoryName && (
              <TextGroup.Secondary as="span">
                {tag.categoryName}/
              </TextGroup.Secondary>
            )}
            {tag.name}
          </TextGroup.Primary>
          {tag.description ? (
            <TextGroup.Secondary variant="small">
              {tag.description}
            </TextGroup.Secondary>
          ) : null}
        </TextGroup.Root>
      </RowLeft>
      <RowRight>
        <ColorBox background={tag.color} variant="small" shape="circle" />
      </RowRight>
    </Row>
  )
}

export const Tag = <T extends BaseTag>({
  tag,
  onClick,
  onDelete,
  disabled,
  css = {}
}: TagProps<T>) => {
  const isDisabled = disabled ?? tag?.disabled

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      if (onClick) onClick(tag, event as any)
    },
    [onClick, tag]
  )

  const handleDelete = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      onDelete(tag, event as any)
    },
    [onDelete, tag]
  )

  return (
    <EnclosedEntity.Root
      background={tag.color}
      disabled={isDisabled}
      onRemove={onDelete ? handleDelete : undefined}
      onClick={handleClick}
      showRemove={Boolean(onDelete)}
    >
      <EnclosedEntity.Text>{tag.name}</EnclosedEntity.Text>
    </EnclosedEntity.Root>
  )
}
