import useSWRImmutable from 'swr/immutable'
import { fetcher } from 'services/tutorials'
import { useOdoSelector } from 'store'
import { authSelectors } from 'store/auth'
import { spacesSelectors } from 'store/spaces'
import { TutorialItem } from 'types/tutorial'

export const getKey = (uid: string, spaceId: string) => {
  return uid && spaceId ? [spaceId, uid, 'tutorials'] : null
}

export const useTutorial = () => {
  const uid = useOdoSelector(authSelectors.uid)
  const spaceId = useOdoSelector(spacesSelectors.currentSpaceId)

  const { data } = useSWRImmutable(getKey(uid, spaceId), ([spaceId, uid]) =>
    fetcher(uid, spaceId)
  )

  const showTutorial = (step: TutorialItem) => {
    return data && data.completed[step] === false
  }

  return {
    tutorial: data,
    selectors: { showTutorial }
  }
}
