import * as actions from 'services/tags'
import randomColor from 'randomcolor'
import { mutate } from 'swr'
import { getKey } from 'hooks/useTags'
import { slugify } from 'utils'
import { store } from 'store'
import { Tag } from 'types/tag'
import { tagsActions, tagsSelectors } from 'store/tags'
import { v4 as uuid, validate as validUuid } from 'uuid'
import { getSpaceId, handleWriteErrorFactory, notify, t } from './shared'

const dispatch = store.dispatch

const handleError = handleWriteErrorFactory(() => {
  const spaceId = getSpaceId()
  mutate(getKey(spaceId))
})

const normalizeTag = (data: Partial<Tag>) => {
  const tag = { ...data }

  if (data.name) {
    tag['name'] = slugify(data.name)
  }

  if (data.categoryId) {
    tag['categoryId'] = validUuid(data.categoryId) ? data.categoryId : null
  }

  return tag
}

type TagOrArrayOfTag<T extends Tag['id'] | Array<Tag['id']> | void = void> =
  T extends void ? Array<Tag> : T extends Tag['id'] ? Tag : Array<Tag>

const getStoreSnapshot = <T extends Tag['id'] | Array<Tag['id']> | void = void>(
  ...args: Partial<T> extends T ? [ids?: T] : [ids: T]
): TagOrArrayOfTag<T> => {
  const current = store.getState()
  const ids = args[0]

  if (typeof ids === 'undefined') {
    return tagsSelectors.selectAll(current) as TagOrArrayOfTag<T>
  }

  if (typeof ids === 'string') {
    return tagsSelectors.selectById(current, ids) as TagOrArrayOfTag<T>
  }

  return ids.map(id =>
    tagsSelectors.selectById(current, id)
  ) as TagOrArrayOfTag<T>
}

const findByName = (name: string) => {
  const current = getStoreSnapshot()
  return current.find(tag => tag.name.toLowerCase() === name.toLowerCase())
}

export const create = (
  values: Partial<Omit<Tag, 'id' | 'spaceId'>> & Pick<Tag, 'name'>
): Tag => {
  const data = normalizeTag(values)

  const repeated = findByName(data.name)

  if (repeated && repeated.archived) {
    const changes = updateArchive(repeated.id, false)

    return {
      ...repeated,
      ...changes
    }
  }

  if (repeated) {
    notify({
      message: t('alerts.tag.duplicted', { name: repeated.name }),
      timeout: 3000
    })

    return repeated
  }

  const id = uuid()

  const tag: Tag = {
    id,
    spaceId: getSpaceId(),
    name: data.name,
    description: data.description,
    color: data.color ?? randomColor(),
    categoryId: data.categoryId,
    createdAt: new Date(),
    archived: false
  }

  dispatch(tagsActions.createTagRequested(tag))

  actions.creator(tag).catch(e => {
    dispatch(tagsActions.createTagFailed(id))
    handleError(e)
  })

  notify({
    message: t('alerts.tag.created', { name: tag.name }),
    timeout: 3000
  })

  return tag
}

export const update = (
  id: string,
  data: Partial<Omit<Tag, 'id' | 'spaceId' | 'createdAt'>>
) => {
  const changes = normalizeTag(data)
  const snapshot = getStoreSnapshot(id)

  dispatch(tagsActions.updateTagRequested({ id, changes }))

  notify({
    message: t('alerts.tag.updated'),
    timeout: 3000
  })

  actions.updater(id, changes).catch(e => {
    dispatch(tagsActions.updateTagFailed(snapshot))
    handleError(e)
  })
}

export const updateArchive = (id: string, archive: boolean) => {
  const changes: Partial<Tag> = {
    categoryId: null,
    archived: archive
  }

  dispatch(tagsActions.updateTagRequested({ id, changes }))

  notify({
    message: t(archive ? 'alerts.tag.archived' : 'alerts.tag.unarchived'),
    timeout: 3000
  })

  actions.updater(id, changes).catch(handleError)

  return changes
}
