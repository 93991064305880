import React, { ComponentProps, ElementRef } from 'react'
import { ColorBox } from '@sc/components/box/ColorBox'
import { IconBox } from '@sc/components/box/IconBox'
import { PictureBox } from '@sc/components/box/PictureBox'
import { TextBox } from '@sc/components/box/TextBox'
import { TextGroup, TextGroupProps } from '@sc/components/TextGroup'
import { styled } from '@sc/theme/stitches.config'

const StyledRoot = styled('div', {
  stackH: '$paddingBetweenLarge',
  alignItems: 'center',
  '&[role="button"]': {
    cursor: 'pointer'
  },
  '&[aria-disabled="true"]': {
    opacity: 0.35,
    pointerEvents: 'none',
    cursor: 'not-allowed !important' // Override role=button
  }
})

type OpenEntityRef = ElementRef<typeof StyledRoot>
export type OpenEntityProps = ComponentProps<typeof StyledRoot> & {
  children: [JSX.Element, JSX.Element] | [JSX.Element, JSX.Element, JSX.Element]
  variant?: TextGroupProps['variant']
  disabled?: boolean
} & TextGroupProps

const Root = React.forwardRef<OpenEntityRef, OpenEntityProps>(
  (
    {
      children,
      level = 'primary',
      orientation = 'horizontal',
      variant = 'base',
      ...props
    },
    ref
  ) => {
    const customProps = Object.assign(
      {},
      'onClick' in props && { role: 'button' },
      props.disabled && { 'aria-disabled': true }
    )

    return (
      <StyledRoot ref={ref} {...customProps} {...props}>
        {children[0]}
        <TextGroup.Root
          level={level}
          orientation={orientation}
          variant={variant}
        >
          {children[1]}
          {children[2]}
        </TextGroup.Root>
      </StyledRoot>
    )
  }
)

export const OpenEntity = {
  Root,
  PictureBox,
  IconBox,
  ColorBox,
  TextBox,
  Text: TextGroup.Primary,
  TextSecondary: TextGroup.Secondary
}
