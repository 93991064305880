import React, { ComponentProps, ElementRef } from 'react'
import { Body } from '@sc/components/Typography'
import { styled } from '@sc/theme/stitches.config'

const InformationBubbleContainer = styled('div', {
  paddingX: '$paddingContainerBubbleX',
  paddingY: '$paddingContainerBubbleY',
  borderRadius: '$L',
  width: '100%',
  display: 'flex',
  backgroundColor: '$market500',
  alignItems: 'left',
  '& > p': {
    flex: 1,
    color: '$whiteSolid'
  }
})

type InformationBubbleRef = ElementRef<typeof InformationBubbleContainer>
export type InformationBubbleProps = ComponentProps<
  typeof InformationBubbleContainer
>

export const InformationBubble = React.forwardRef<
  InformationBubbleRef,
  InformationBubbleProps
>(({ children, ...props }, ref) => {
  return (
    <InformationBubbleContainer ref={ref} {...props}>
      <Body variant="base">{children}</Body>
    </InformationBubbleContainer>
  )
})
