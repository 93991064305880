import { CSS } from '@stitches/react'
import { styled } from 'theme/stitches.config'

export const basePanelStyles: CSS = {
  backgroundColor: '$surfaceFg',
  borderRadius: '$panel'
}

export const basePopoverPanelStyles: CSS = {
  ...basePanelStyles,
  boxShadow: '$panel',
  border: '$borderWidths$default solid $colors$outline'
}

export const Panel = styled('div', basePanelStyles)

export const PopoverPanel = styled('div', {
  backgroundColor: '$surfaceFg',
  border: 'none',
  borderRadius: '$M',
  boxShadow: '$levelOne',
  pointerEvents: 'auto',
  overflow: 'hidden',
  outline: 'none',
  zIndex: '$popover'
})
