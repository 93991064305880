import React, { ElementRef, ComponentProps } from 'react'
import tinycolor from 'tinycolor2'
import { IconBox, IconBoxProps } from '@sc/components/box/IconBox'
import { PictureBox, PictureBoxProps } from '@sc/components/box/PictureBox'
import { TextBox, TextBoxProps } from '@sc/components/box/TextBox'
import { Body, BodyProps } from '@sc/components/Typography'
import { styled } from '@sc/theme/stitches.config'

const Box = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$paddingBetweenMed',

  height: '$controlHeight',
  paddingLeft: '$paddingUIL',
  paddingRight: '$paddingUIL',
  width: 'fit-content',
  borderRadius: '$S',

  '&[role="button"]': {
    cursor: 'pointer'
  },

  '&:has(> div[data-icon-box="true"]:last-child)': {
    paddingRight: '$paddingUIS'
  },

  '&[aria-disabled="true"]': {
    opacity: 0.35,
    pointerEvents: 'none',
    cursor: 'not-allowed !important' // Override role=button
  }
})

const Text = ({ css = {}, ...props }: Omit<BodyProps, 'variant' | 'as'>) => (
  <Body
    as="span"
    variant="base"
    css={{ color: 'inherit', ...css }}
    {...props}
  />
)

type EnclosedEntityRef = ElementRef<typeof Box>
export type EnclosedEntityProps = ComponentProps<typeof Box> & {
  background: string
  showRemove?: boolean
  children: JSX.Element | [JSX.Element, JSX.Element]
  disabled?: boolean
  onRemove?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Root = React.forwardRef<EnclosedEntityRef, EnclosedEntityProps>(
  (
    { background, children, showRemove = false, css = {}, onRemove, ...props },
    ref
  ) => {
    const customProps = Object.assign(
      {},
      Boolean(props.onClick) && { role: 'button' },
      props.disabled && { 'aria-disabled': true }
    )

    // Remove button should be shown when the entity is not disabled.
    const showRemoveButton = Boolean(showRemove && !props.disabled)

    const bg = tinycolor(background)

    if (!bg.isValid()) console.error('Invalid background color token')

    const color = bg.isLight() ? '$blackSolid' : '$whiteSolid'

    return (
      <Box
        ref={ref}
        css={{ background, color, ...css }}
        {...customProps}
        {...props}
      >
        {children}

        {showRemoveButton && (
          <IconBox
            role="button"
            // By default applies theme color
            iconCss={{ color }}
            name="close"
            variant="small"
            shape="square"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()

              if (onRemove) onRemove(event)
            }}
          />
        )}
      </Box>
    )
  }
)

type PictureBoxRef = ElementRef<typeof PictureBox>

const StyledPictureBox = React.forwardRef<PictureBoxRef, PictureBoxProps>(
  (props, ref) => <PictureBox variant="small" ref={ref} {...props} />
)

type IconBoxRef = ElementRef<typeof IconBox>

const StyledIconBox = React.forwardRef<IconBoxRef, IconBoxProps>(
  (props, ref) => <IconBox variant="small" ref={ref} {...props} />
)

const StyledTextBox = (props: Omit<TextBoxProps, 'variant'>) => (
  <TextBox variant="small" {...props} />
)

export const EnclosedEntity = {
  Root,
  Text,
  PictureBox: StyledPictureBox,
  IconBox: StyledIconBox,
  TextBox: StyledTextBox
}
