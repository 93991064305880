import * as SelectPrimitive from '@radix-ui/react-select'
import { styled } from '@sc/theme/stitches.config'
import { ComponentProps } from '@stitches/react'
import { ActionList } from './ActionList'
import { IconBox } from './box/IconBox'

const StyledTrigger = styled(SelectPrimitive.Trigger, {
  all: 'unset',
  boxSizing: 'border-box',

  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  width: '100%',
  height: '$controlHeight',
  paddingX: '$paddingUIM',

  color: '$text',
  backgroundColor: 'transparent',

  fontFamily: '$octave',
  fontSize: '$base',
  lineHeight: '$3',
  fontWeight: '$normal',

  borderWidth: '$control',
  borderColor: '$strokePrimary',
  borderStyle: 'solid',
  borderRadius: '$S',

  '&:disabled': {
    borderColor: '$strokeSecondary',
    color: '$fgLow'
  },

  '&:focus': {
    borderColor: '$strokeFocus'
  },

  '&[data-placeholder]': {
    color: '$fgMed'
  },

  '&:focus-visible': { boxShadow: '$focus', outline: 'none' }
})

export type SelectProps = SelectPrimitive.SelectProps & {
  disabled?: SelectPrimitive.SelectTriggerProps['disabled']
  placeholder?: SelectPrimitive.SelectValueProps['placeholder']
}

const Root = ({ children, placeholder, disabled, ...props }: SelectProps) => {
  return (
    <SelectPrimitive.Root {...props}>
      <StyledTrigger disabled={disabled}>
        <SelectPrimitive.Value placeholder={placeholder} />

        <SelectPrimitive.Icon asChild>
          <IconBox name="chevronDown" variant="small" />
        </SelectPrimitive.Icon>
      </StyledTrigger>

      <SelectPrimitive.Portal>
        <ActionList.Root as={SelectPrimitive.Content}>
          <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
        </ActionList.Root>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  )
}

export type ItemProps = ComponentProps<typeof SelectPrimitive.Item>

const Item = ({ children, ...props }: ItemProps) => {
  return (
    <ActionList.Item as={SelectPrimitive.Item} {...props}>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>

      <SelectPrimitive.ItemIndicator asChild>
        <IconBox name="check" variant="small" />
      </SelectPrimitive.ItemIndicator>
    </ActionList.Item>
  )
}

export const Select = { Root, Item }
