import { Popover, PopoverContent, PopoverTrigger } from '../primitives/Popover'
import { styled } from '../theme/stitches.config'
import { IconBox } from './box/IconBox'
import { Divider } from './Divider'
import { FormButton } from './form/FormButton'
import { FormGroup } from './form/FormGroup'
import { Input } from './Input'
import { Label } from './Label'
import { TextArea } from './TextArea'

const DEFAULT_COLORS = [
  '#5F4A00',
  '#A10B2B',
  '#202D85',
  '#3D723B',
  '#C05200',
  '#E0B81F',
  '#E64225',
  '#0D75FF',
  '#888D2A',
  '#FF7340',
  '#EFEDB6',
  '#FFD2BF',
  '#99BAD9',
  '#BBD8A8',
  '#FFD57E'
]

const StyledPopoverContent = styled(PopoverContent, {
  backgroundColor: '$surfaceFg',
  borderRadius: '$M',
  boxShadow: '$levelTwo'
})

const ColorGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: '$paddingBetweenSmall',
  padding: '$paddingContainerLevelOne',
  placeItems: 'center'
})

const Description = styled('div', {
  padding: '$paddingContainerLevelOne'
})

type ColorPickerProps = {
  value: string
  description?: string
  colors?: string[]
  onValueChange: (color: string) => void
  disabled?: boolean
}

export const ColorPicker = ({
  value,
  colors = DEFAULT_COLORS,
  onValueChange,
  disabled,
  description
}: ColorPickerProps) => {
  return (
    <Popover modal>
      <PopoverTrigger asChild>
        <FormButton aria-label="Open color picker" disabled={disabled}>
          <IconBox
            name="circle"
            variant="small"
            iconCss={{ color: value, opacity: disabled ? 0.2 : 1 }}
          />

          {!disabled && <IconBox name="chevronDown" variant="small" />}
        </FormButton>
      </PopoverTrigger>

      <StyledPopoverContent sideOffset={10} align="start">
        {description && (
          <>
            <Description>
              <TextArea>{description}</TextArea>
            </Description>

            <Divider css={{ width: '100%' }} />
          </>
        )}

        <ColorGrid>
          {colors.map((color) => (
            <FormButton
              key={color}
              type="button"
              data-selected={value === color}
              onClick={() => onValueChange(color)}
              aria-label={`Select color ${color}`}
            >
              <IconBox name="circle" variant="small" iconCss={{ color }} />
            </FormButton>
          ))}
        </ColorGrid>

        <Divider css={{ width: '100%' }} />

        <FormGroup css={{ padding: '$paddingContainerLevelOne' }}>
          <Label.Root>
            <Label.Primary htmlFor="hex">Custom</Label.Primary>
            <Label.Secondary>Accepts HEX</Label.Secondary>
          </Label.Root>

          <Input
            type="text"
            name="hex"
            id="hex"
            placeholder="#000000"
            value={value}
            onChange={(event) => onValueChange(event.target.value)}
          />
        </FormGroup>
      </StyledPopoverContent>
    </Popover>
  )
}
