import * as actions from 'services/actions/tutorialActions'
import { Button } from '@sc/components/Button'
import { ComponentProps, Children } from 'react'
import { InformationBubble } from '@sc/components/InformationBubble'
import { styled } from 'theme/stitches.config'
import { TutorialItem } from 'types/tutorial'
import { useTutorial } from 'hooks/useTutorial'
import { Div } from '@sc/components/Div'

const Bubble = styled(InformationBubble, {
  marginBottom: '$paddingBetweenLarge'
})

const Root = styled('div', {
  width: 'min($articleWidthM, 100%)',
  marginX: 'auto'
})

type DismissableProps = ComponentProps<typeof Root> & {
  item: TutorialItem
}

const Dismissable = ({ item, children, ...props }: DismissableProps) => {
  const { tutorial, selectors } = useTutorial()
  const show = selectors.showTutorial(item)

  const bubbleElements = Children.toArray(children).filter(child => {
    return typeof child === 'object' && 'type' in child && child.type === Bubble
  })

  const actionElements = Children.toArray(children).filter(child => {
    return typeof child === 'object' && 'type' in child && child.type === Button
  })

  if (show) {
    return (
      <Root {...props}>
        {bubbleElements}

        <Div css={{ stackH: '$paddingBetweenMed' }}>
          {actionElements}

          <Button
            variant="secondary"
            onClick={() => actions.complete(tutorial, item)}
          >
            Dismiss
          </Button>
        </Div>
      </Root>
    )
  }

  return null
}

export const Tutorial = { Dismissable, Bubble, Action: Button }
