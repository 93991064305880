import { styled } from '@sc/theme/stitches.config'

export const FormButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',

  height: '$controlHeight',
  paddingLeft: '$paddingUIM',
  paddingRight: '$paddingUIM',

  stackH: '$paddingBetweenSmall',
  alignItems: 'center',
  justifyContent: 'center',

  borderWidth: '$borderWidths$control',
  borderStyle: 'solid',
  borderColor: '$strokePrimary',
  borderRadius: '$S',
  cursor: 'pointer',

  '&:disabled': {
    borderColor: '$strokeSecondary',
    cursor: 'not-allowed'
  },

  '&:not(:disabled):active': {
    borderColor: '$strokeFocus'
  },

  // For radix triggers
  '&[data-state="open"]': { borderColor: '$strokeFocus' },

  // To indicate that the button is selected or active manually
  '&[data-selected="true"]': { borderColor: '$strokeFocus' }
})
