import React, { ComponentProps, ElementRef } from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { IconBox } from '@sc/components/box/IconBox'
import { Label } from '@sc/components/Label'
import { Row, RowLeft, RowRight } from '@sc/components/Row'
import { CSS, styled } from '@sc/theme/stitches.config'

const iconCss: CSS = {
  backgroundColor: '$surfaceBg',
  borderRadius: '$M'
}

const StyledItem = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  display: 'block',
  boxSizing: 'border-box',
  paddingLeft: '$paddingContainerLevelOne',
  paddingRight: '$paddingContainerLevelOne',
  // Use this instead of padding to set the specific height value
  minHeight: 'calc($controlHeight + $space$paddingContainerLevelOne * 2)',
  borderRadius: '$S',
  borderWidth: '$control',
  borderStyle: 'solid',
  borderColor: '$strokeSecondary',
  '&[data-state="checked"]': {
    borderColor: '$strokeFocus'
  }
})

const Root = styled(RadioGroupPrimitive.Root, {
  stack: '$paddingBetweenLarge'
})

type ItemRef = ElementRef<typeof StyledItem>
type ItemProps = ComponentProps<typeof StyledItem> & {
  children: string
  description?: string
}

const Item = React.forwardRef<ItemRef, ItemProps>(
  ({ children, description = '', ...props }, ref) => {
    const id = props.id || props.value

    return (
      <StyledItem id={id} ref={ref} {...props}>
        <Row>
          <RowLeft stretch>
            <Label.Root orientation="vertical">
              <Label.Primary htmlFor={id}>{children}</Label.Primary>
              <Label.Secondary>{description}</Label.Secondary>
            </Label.Root>
          </RowLeft>

          <RowRight>
            <RadioGroupPrimitive.Indicator asChild>
              <IconBox name="check" variant="large" css={iconCss} />
            </RadioGroupPrimitive.Indicator>
          </RowRight>
        </Row>
      </StyledItem>
    )
  }
)

export const SelectList = {
  Root,
  Item
}
